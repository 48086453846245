import React from 'react'
import Layout from "../layouts/index";
import SEO from '../components/seo'
import '../style/index.scss';
import { graphql } from 'gatsby'; 

const LegalPage = ({ data }) => (
<Layout children={null} data={data}>
    <SEO 
        title={data.legalPages.edges[0].node.seo.seo.title}
        keywords={data.legalPages.edges[0].node.seo.keywords}
        slug=''
        description={data.legalPages.edges[0].node.seo.seo.description}
        lang={data.legalPages.edges[0].node.seo.locale}
        contactinfo={JSON.parse(data.contactinfo.data)}
    />
  <div>
    {
        data.legalPages.edges.map(({node: legalPage}, index) => (
            <div className="legal-page" key={index}>
                <div className="legal-page__grid">
                    <h1 className="legal-page__grid-title">
                        <span dangerouslySetInnerHTML={{__html: legalPage.legalPageTitle}}></span>
                    </h1>
                    <div className="legal-page__grid-wrapper">
                        <div className="legal-page__item" key={index}>                                
                            <span className="legal-page__item-text"> 
                                {
                                    legalPage.legalPageContentWrapper.map((contentItem, contentItemIdex) => (
                                        <div className="legal-page__item-text-paragraph" key={contentItemIdex} dangerouslySetInnerHTML={{__html: contentItem.legalPageTextContent}}/>
                                    ))
                                }
                            </span>
                       </div>
                    </div>
                </div>
            </div>
        ))
    }
  </div>
  </Layout>
)

/* Legal pages Query */
export const query = graphql`
query ($locale: String!, $legalPageId: String!) {
    legalPages: allDatoCmsLegalPage(filter: {locale: {eq: $locale}, id: {eq: $legalPageId}}) {
        edges {
            node {
                legalPageUrl
                locale
                legalPageTitle
                legalPageContentWrapper {
                    legalPageTextContent
                }
                seo {
                    id
                    locale
                    seo {
                      description
                      title
                      image {
                        id
                        url
                      }
                    }
                    slug
                    keywords
                }
            }
        }
    }
    categories: allDatoCmsCategory(sort: {order: ASC, fields: sequence}, filter: {top: {eq: true}, locale: {eq: $locale}}) {
        edges {
            node {
                id
                name
                url
                sequence
                locale
                subcategory {
                  id
                  name
                  url
                  locale
                  sequence
                  subcategory {
                    id
                    name
                    url
                    locale
                    sequence
                  }
                }
            }
        }
    }
    contentPages: allDatoCmsContentPage(filter: {locale: {eq: $locale}}) {
        edges {
            node {
                locale
                url
                cpContentGridTitle
            }
        }
    }
    allDatoCmsFooter(filter: {locale: {eq: $locale}}) {
        edges {
            node {
                footerLinksList
                footerCopyright
                footerPayments
            }
        }
    }
    contactinfo : datoCmsExtrainfo(name: {eq: "contactinfo"}, locale: {eq: $locale}) {
        data
    }
    ddm : allDatoCmsDdm(filter: {locale: {eq: $locale}}) {
        nodes {
            submenu {
                header
                directLink
                menu
            }
        }
    }
}
`
export default LegalPage;
